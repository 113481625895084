<template>
  <div class="menu-edit-page w-100">
    <basic-subheader title="Chỉnh sửa thực đơn">
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button class="btn ml-3" type="button" @click="resetData">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
          </span>
          Reset dữ liệu
        </b-button>
        <b-button
          v-if="showBtnActive"
          class="btn btn-primary ml-3"
          type="button"
          @click="showPopupChangeStatus(true)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          v-else
          class="btn btn-danger ml-3"
          type="button"
          @click="showPopupChangeStatus(false)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          @click="handleValidate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-card>
        <b-overlay :show="loading">
          <div>
            <from-menu
              ref="form-menu"
              :data="detail"
              type="edit"
              @submit="submit"
            />
            <template v-if="listdayfood && listdayfood.length">
              <template v-for="(dayFood, index) in listdayfood">
                <collapse-menu
                  :key="index"
                  :id="`${dayFood.dateCode}-${index}`"
                  :item="dayFood"
                  :is-active="!showBtnActive"
                />
              </template>
            </template>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  GET_FOOD_TEMPLATES,
  GET_MENU_DETAIL,
  CHANGE_STATUS,
} from '@/core/services/store/menu.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('menu');
export default {
  name: 'MenuEdit',
  components: {
    'from-menu': () => import('./components/Form.vue'),
    'collapse-menu': () => import('./components/Collapse.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  computed: {
    ...mapState(['listdayfood', 'deleteFoodSuccess', 'submitFoodSuccess']),
    showBtnActive() {
      if ([0, 2].includes(this.detail?.status)) return true;
      return false;
    },
  },
  watch: {
    deleteFoodSuccess() {
      this.getMenuDetail();
    },
    submitFoodSuccess() {
      this.getMenuDetail();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ GET_FOOD_TEMPLATES, GET_MENU_DETAIL, CHANGE_STATUS }),
    loadData() {
      if (this.id) {
        this.getMenuDetail();
      }
    },
    async getMenuDetail() {
      this.loading = true;
      const { data, error } = await this.GET_MENU_DETAIL({ id: this.id });
      if (!error) {
        this.detail = data;
        await this.GET_FOOD_TEMPLATES({
          foodMenuCode: data.code,
        });
      }
      this.loading = false;
    },
    submit(params) {
      params['id'] = this.id;
      return this.updateMenuFood(params);
    },
    async updateMenuFood(params) {
      this.loading = true;
      try {
        let { meta, data } = await this.$api.put('/FoodMenu/Input', params);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: data.message,
          });
        }
        this.$refs['form-menu'].resetValidateForm();
        this.showToastrSuccess();
        return this.returnPage();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    returnPage() {
      this.$router.push({
        name: 'menu_list',
      });
    },
    showToastrSuccess() {
      this.$toastr.s({
        title: 'Thành công !',
        msg: 'Cập nhật thành công',
      });
    },
    handleValidate() {
      this.$refs['form-menu'].handleValidate();
    },
    async changeStatus(isActive) {
      this.loading = true;
      const params = {
        isActive,
        id: this.id,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.showToastrSuccess();
        this.getMenuDetail();
      } else {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error.message,
        });
      }
      this.loading = false;
    },
    resetData() {
      this.detail.code = null;
      this.detail.menuTitle = null;
      this.detail.note = null;
      this.detail.allowSugguestionMenu = false;
    },
    showPopupChangeStatus(isActive) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          isActive ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(isActive);
          }
        }.bind(this),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.btn.btn-danger {
  color: #fff !important;
  background-color: #ff5756 !important;
  border: none !important;
}
</style>
